import React, { useEffect, useState } from 'react';

export const Adsense = ({ slotId, adId, adStatusCallback, footerAd }) => {

    useEffect(() => {
        if (window) (window.adsbygoogle = window.adsbygoogle || []).push({});

        let observer = null;
        let timeout = null;

        if (adId) {
            const adElem = document.getElementById(adId);
            if (adElem) {
                const config = { attributes: true, childList: false, subtree: false };
    
                observer = new MutationObserver((mutationList, observer) => {
                    let mutated = mutationList.find(elem => { return elem.type === 'attributes' && elem.attributeName === 'data-ad-status' });
                    if (mutated) {
                        if(timeout) {
                            clearTimeout(timeout);
                        }
                        adStatusCallback(mutated.target.getAttribute('data-ad-status'));
                    }
                }); 
    
                observer.observe(adElem, config);
            }
        }
        
        // timeout = setTimeout(() => {
        //     if (adStatusCallback) {
        //         adStatusCallback("unfilled");
        //     }
        // }, 2000);

        return () => {
            if (observer) {
                observer.disconnect();
            }
        }
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <ins id={adId} className="adsbygoogle"
                style={footerAd ? {display: "flex", alignItems: "center", justifyContent: "center"} : { display: 'block' }}
                data-ad-client="ca-pub-2873705349997362"
                data-ad-slot={slotId}
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
        </div>
    );
};

export class AdsenseFiller {  
    
    constructor() {
        this.pageSize = 0;
        this.tempData = [];
        this.unFilledIndexes = [];
        this.iteration = 0;
        this.removeTimeout = null;
        this.removeIds = [];
    }

    getGoogleAdData = (previousData, currentData, interval, pageSize, isLastPage=false) => {
        this.iteration += 1;
        this.pageSize = pageSize;
        let displayData = [], maxLength = pageSize * this.iteration, i = 0;

        if ( previousData.length > 0 ) {
            currentData = previousData.concat(this.getTempData(), currentData);
            this.tempData = [];
            this.removeIds = [];
        }

        currentData.map((elem, index) => {
            let calculatedIndex = index + i;

            // In each particular place we need to display the ad. Calculated based on interval.
            if ( calculatedIndex != 0 && (calculatedIndex + 1) % interval == 0 ) {

                // if there is showAd already the just using that element.
                if ( elem.showAd ) {
                    displayData.push(elem);
                } else {
                    // Looks like showAd is been replaced with actual data here.

                    // if the displayData length exceeds the maxLength then pushing it to the tempData
                    if ( displayData.length >= maxLength ) {
                        this.tempData.push(elem);
                    } else if ( this.unFilledIndexes.includes(index) ) {
                        // Here displayData not exceeds with maxLength.
                        // if this index is found in unfilled indexes then pushing it to display data (The ad in this index was already unfilled in previous rendering)
                        displayData.push(elem);
                    } else {
                        // Here displayData not exceeds with maxLength.
                        // Pushing AD to show
                        displayData.push({id: Date.now() + Math.floor(1000 + Math.random() * 9000), showAd: true});

                        // After pushing the ad checking if the displayData length exceeds the maxLength then pushing the data to tempData
                        if ( displayData.length >= maxLength ) {
                            this.tempData.push(elem);
                        } else {
                            displayData.push(elem);
                            if ( pageSize > interval ) {
                                i += 1;
                            }
                        }
                    }
                }
            } else if ( displayData.length < maxLength ) {
                // If the displayData length is withing the maxLength then pushing to displayData
                displayData.push(elem);
            } else {
                // Here the displayData length exceeds the maxLength then pushing to tempData
                this.tempData.push(elem);
            }
        });

        if ( isLastPage ) {
            displayData = [...displayData, ...this.getTempData()];
            this.tempData = [];
        }

        return displayData;
    };

    removeAdsBasedOnStatus = async (id, adstatus, data) => {
        if ( this.removeTimeout != null ) {
            clearTimeout(this.removeTimeout);
        }

        if ( adstatus !== 'filled' ) {
            this.removeIds = [...this.removeIds, id];
        }

        return new Promise(resolve => {
            this.removeTimeout = setTimeout(() => {
                let displayData = [];
                data.forEach((element, index) => {
                    if ( this.removeIds.includes(element.id) ) {
                        this.unFilledIndexes = [...this.unFilledIndexes, index];
                    } else {
                        displayData.push(element);
                    }
                });

                if ( displayData.length < (this.pageSize * this.iteration) ) {
                    let tempData = this.getTempData();
                    if ( tempData.length > 0 && this.removeIds.length > 0 ) {
                        let splicedData = tempData.splice(0, this.removeIds.length);
                        displayData = [...displayData, ...splicedData];
                        this.tempData = tempData;
                    }
                }
    
                return resolve(displayData);
            }, 500);
        });
    };

    removeAdsBasedOnStatusForMultipleAds = async (id, adstatus, data) => {
        if ( this.removeTimeout != null ) {
            clearTimeout(this.removeTimeout);
        }

        if ( adstatus !== 'filled' ) {
            this.removeIds = [...this.removeIds, id];
        }

        return new Promise(resolve => {
            this.removeTimeout = setTimeout(() => {
                let displayData = [];
                let adRemoved = false;
                let temp = null;
                data.forEach((element, index) => {
                    if ( this.removeIds.includes(element.id) ) {
                        adRemoved = true;
                        this.unFilledIndexes = [...this.unFilledIndexes, index];
                    } else {
                        if ( adRemoved && element.showAd ) {
                            temp = element;
                        } else if ( temp ) {
                            displayData.push(element);
                            displayData.push(temp);
                            temp = null;
                        } else {
                            displayData.push(element);
                        }
                    }
                });

                if ( displayData.length < (this.pageSize * this.iteration) ) {
                    let tempData = this.getTempData();
                    if ( tempData.length > 0 && this.removeIds.length > 0 ) {
                        let splicedData = tempData.splice(0, this.removeIds.length);
                        displayData = [...displayData, ...splicedData];
                        this.tempData = tempData;
                        this.removeIds = [];
                    }
                }

                if ( temp ) {
                    displayData = [...displayData, temp];
                }

                return resolve(displayData);
            }, 500);
        });
    };

    getTempData = () => {
        return this.tempData;
    };
};